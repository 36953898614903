import { createAction } from 'redux-actions'
import * as types from './types'

export * from './firmwares/actions'

export const fetchOrganizations = createAction(types.FETCH_ORGANIZATIONS)
export const fetchedOrganizations = createAction(
  types.FETCH_ORGANIZATIONS_SUCCESS,
)

export const addOrganization = createAction(types.ADD_ORGANIZATION)
export const addedOrganization = createAction(types.ADD_ORGANIZATION_SUCCESS)

export const editOrganization = createAction(types.EDIT_ORGANIZATION)
export const editedOrganization = createAction(types.EDIT_ORGANIZATION_SUCCESS)

export const fetchDevices = createAction(types.FETCH_DEVICES)
export const fetchedDevices = createAction(types.FETCH_DEVICES_SUCCESS)

export const addDevice = createAction(types.ADD_DEVICE)
export const addedDevice = createAction(types.ADD_DEVICE_SUCCESS)

export const editDevice = createAction(types.EDIT_DEVICE)
export const editedDevice = createAction(types.EDIT_DEVICE_SUCCESS)
