function module(state) {
  return state.organization.assets
}

export function getAssets(state) {
  return module(state).list
}

export function isUploadingAssets(state) {
  return module(state).uploading
}
