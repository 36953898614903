import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  fetchContent,
  getContentPresets,
  isLoadingContentPresets,
} from 'modules/organization'
import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Image,
  Heading,
  Grid,
  Text,
} from 'grommet'
import { Actions, History } from 'grommet-icons'

function PresetAsset(asset) {
  return (
    <Box key={asset.id} width="small">
      <Image src={asset.thumbnailUrl} fit="contain" />
    </Box>
  )
}

function createPresetRenderer({ active = false } = {}) {
  return function Preset(preset) {
    let labelText = preset.name
    if (active) {
      labelText += ` (запущен на устройствах)`
    }
    const color = active ? 'brand' : 'dark-2'
    const Icon = active ? Actions : History
    const label = (
      <Box direction="row" gap="small">
        <Icon color={color} />
        <Text weight="bold" color={color}>
          {labelText}
        </Text>
      </Box>
    )
    return (
      <AccordionPanel key={preset.id} label={label} pad="medium">
        <Box margin={{ horizontal: 'medium', bottom: 'medium' }}>
          <Grid columns="small" rows="small" gap="xsmall">
            {preset.assets.map(PresetAsset)}
          </Grid>
        </Box>
      </AccordionPanel>
    )
  }
}

class Content extends React.Component {
  componentDidMount() {
    const { fetchContent } = this.props
    fetchContent()
  }

  renderCurrentPreset = createPresetRenderer({
    active: true,
  })

  renderPreviousPreset = createPresetRenderer({
    active: false,
  })

  renderContent = () => {
    const { currentPreset, previousPresets, loading } = this.props
    if (loading) {
      return (
        <Box fill justify="center" align="center">
          <Heading level={3}>Загрузка...</Heading>
        </Box>
      )
    }
    const hasContent = currentPreset || previousPresets.length > 0
    if (hasContent) {
      return (
        <Accordion multiple>
          {currentPreset && this.renderCurrentPreset(currentPreset)}
          {previousPresets.map(this.renderPreviousPreset)}
        </Accordion>
      )
    }
    return (
      <Box fill justify="center" align="center" pad="medium">
        <History size="xlarge" color="brand" />
        <Box pad="medium">
          <Heading level={3} textAlign="center">
            Здесь будет отображаться история ваших загрузок
          </Heading>
        </Box>
        <Link to="/">
          <Button pad="small" align="center">
            <Text>Загрузить контент из Галлереи</Text>
          </Button>
        </Link>
      </Box>
    )
  }

  render() {
    return <Box fill>{this.renderContent()}</Box>
  }
}

function mapState(state) {
  const { list, current } = getContentPresets(state)
  return {
    loading: isLoadingContentPresets(state),
    previousPresets: list,
    currentPreset: current,
  }
}

const mapDispatch = {
  fetchContent,
}

export default connect(
  mapState,
  mapDispatch,
)(Content)
