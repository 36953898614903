import React from 'react'
import { Box, Button, Layer } from 'grommet'

class Fab extends React.Component {
  renderButton = action => {
    const { key, icon: Icon, color, onClick } = action
    return (
      <Box
        key={key}
        round="full"
        pad="medium"
        margin="medium"
        overflow="hidden"
        background={color || 'brand'}
      >
        <Button icon={<Icon size="medium" />} onClick={onClick} />
      </Box>
    )
  }

  render() {
    const { actions } = this.props
    return (
      <Layer
        animate
        plain
        modal={false}
        responsive={false}
        position="bottom-right"
      >
        {actions.map(this.renderButton)}
      </Layer>
    )
  }
}

export default Fab
