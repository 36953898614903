export * from './assets/selectors'
export * from './content/selectors'
export * from './devices/selectors'
export * from './employees/selectors'

export function getOrganization(state) {
  return state.account.user.organization
}

export function getOrganizationName(state) {
  return state.organization.name
}

export function getCities(state) {
  return state.organization.cities
}
