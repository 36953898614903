export const FETCH_EMPLOYEES = 'organization/employees/FETCH'
export const FETCH_EMPLOYEES_SUCCESS = 'organization/employees/FETCH_SUCCESS'

export const ADD_EMPLOYEE = 'organization/employees/ADD'
export const ADD_EMPLOYEE_SUCCESS = 'organization/employees/ADD_SUCCESS'

export const EDIT_EMPLOYEE = 'organization/employees/EDIT'
export const EDIT_EMPLOYEE_SUCCESS = 'organization/employees/EDIT_SUCCESS'

export const REMOVE_EMPLOYEE = 'organization/employees/REMOVE'
export const REMOVE_EMPLOYEE_SUCCESS = 'organization/employees/REMOVE_SUCCESS'
