import { call, put, spawn, takeLeading } from 'redux-saga/effects'
import { RequestQueryBuilder } from '@nestjsx/crud-request'
import api from 'api'
import { notifySuccess } from 'modules/notifications'
import firmwares from './firmwares/saga'
import * as actions from './actions'
import * as types from './types'

function* handleFetchOrganization() {
  const query = RequestQueryBuilder.create()
    .setJoin({ field: 'cities' })
    .query()
  const organizations = yield call(
    [api.organizations, api.organizations.getAll],
    query,
  )
  yield put(actions.fetchedOrganizations(organizations))
}

function* handleAddOrganization({ payload }) {
  const { owner, organization } = payload
  let user = null
  try {
    user = yield call([api.users, api.users.add], {
      ...owner,
      password: '123',
    })
    const org = yield call([api.organizations, api.organizations.add], {
      ...organization,
      owner: user.id,
    })
    yield put(actions.addedOrganization(org))
    yield put(notifySuccess('Организация создана'))
  } catch (err) {
    if (user) {
      yield call([api.users, api.users.delete], user.id)
    }
  }
}

function* handleEditOrganization({ payload: { id: itemId, changes } }) {
  yield call([api.organizations, api.organizations.update], {
    itemId,
    changes,
  })
  yield put(actions.editedOrganization({ id: itemId, changes }))
  yield put(notifySuccess('Ваши изменения сохранены'))
}

function* handleFetchDevices() {
  const devices = yield call([api.devices, api.devices.getAll])
  yield put(actions.fetchedDevices(devices))
}

function* handleAddDevice({ payload }) {
  const device = yield call([api.devices, api.devices.add], payload)
  yield put(actions.addedDevice(device))
  yield put(notifySuccess('Устройство создано'))
}

function* handleEditDevice({ payload: { id: itemId, changes } }) {
  yield call([api.devices, api.devices.update], {
    itemId,
    changes,
  })
  yield put(actions.editedDevice({ id: itemId, changes }))
  yield put(notifySuccess('Ваши изменения сохранены'))
}

function* main() {
  yield takeLeading(types.FETCH_ORGANIZATIONS, handleFetchOrganization)
  yield takeLeading(types.ADD_ORGANIZATION, handleAddOrganization)
  yield takeLeading(types.EDIT_ORGANIZATION, handleEditOrganization)

  yield takeLeading(types.FETCH_DEVICES, handleFetchDevices)
  yield takeLeading(types.ADD_DEVICE, handleAddDevice)
  yield takeLeading(types.EDIT_DEVICE, handleEditDevice)

  yield spawn(firmwares)
}

export default main
