import { handleActions } from 'redux-actions'
import { BUILD_STATUS } from './common'
import * as types from './types'

const initialState = {
  list: [],
  fetching: false,
  status: BUILD_STATUS.IDLE,
}

const handlers = {
  [types.FETCH_CONTENT]: state => ({
    ...state,
    fetching: true,
  }),
  [types.FETCH_CONTENT_SUCCESS]: (state, action) => ({
    ...state,
    list: action.payload,
    fetching: false,
  }),
  [types.BUILD_CONTENT]: state => ({
    ...state,
    status: BUILD_STATUS.PROGRESS,
  }),
  [types.CONTENT_BUILD_STATUS_CHANGE]: (state, action) => ({
    ...state,
    status: action.payload,
  }),
}

export default handleActions(handlers, initialState)
