import { createAction } from 'redux-actions'
import * as types from './types'

export const checkAccess = createAction(types.CHECK_ACCESS)

export const signIn = createAction(types.SIGN_IN)
export const signedIn = createAction(types.SIGN_IN_SUCCESS)
export const signInError = createAction(types.SIGN_IN_ERROR)

export const signOut = createAction(types.SIGN_OUT)
