import { combineReducers } from 'redux'
import assets from './assets'
import content from './content'
import devices from './devices'
import employees from './employees'
import * as types from './types'

function nameReducer(state = '', { type, payload }) {
  if (type !== types.FETCH_ORGANIZATION_SUCCESS) {
    return state
  }
  return payload.name
}

function citiesReducer(state = [], { type, payload }) {
  if (type !== types.FETCH_ORGANIZATION_SUCCESS) {
    return state
  }
  return payload.cities
}

export default combineReducers({
  name: nameReducer,
  cities: citiesReducer,
  assets,
  content,
  devices,
  employees,
})
