export * from './firmwares/types'

export const FETCH_ORGANIZATIONS = 'admin/organizations/FETCH'
export const FETCH_ORGANIZATIONS_SUCCESS = 'admin/organizations/FETCH_SUCCESS'

export const ADD_ORGANIZATION = 'admin/organizations/ADD'
export const ADD_ORGANIZATION_SUCCESS = 'admin/organizations/ADD_SUCCESS'

export const EDIT_ORGANIZATION = 'admin/organizations/EDIT'
export const EDIT_ORGANIZATION_SUCCESS = 'admin/organizations/EDIT_SUCCESS'

export const FETCH_DEVICES = 'admin/organization/devices/FETCH'
export const FETCH_DEVICES_SUCCESS = 'admin/organization/devices/FETCH_SUCCESS'

export const ADD_DEVICE = 'admin/organization/devices/ADD'
export const ADD_DEVICE_SUCCESS = 'admin/organization/devices/ADD_SUCCESS'

export const EDIT_DEVICE = 'admin/organization/devices/EDIT'
export const EDIT_DEVICE_SUCCESS = 'admin/organization/devices/EDIT_SUCCESS'
