import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import firmwares from './firmwares'
import * as types from './types'

const organizations = handleActions(
  {
    [types.FETCH_ORGANIZATIONS_SUCCESS]: (state, action) => action.payload,
    [types.ADD_ORGANIZATION_SUCCESS]: (state, action) => [
      ...state,
      action.payload,
    ],
  },
  [],
)

const device = handleActions(
  {
    [types.ADD_DEVICE_SUCCESS]: (state, action) => [
      action.payload,
      ...state.devices,
    ],
  },
  [],
)

export default combineReducers({
  organizations,
  device,
  firmwares,
})
