import { call, put, takeLeading } from 'redux-saga/effects'
import api from 'api'
import { notifySuccess } from 'modules/notifications'
import * as actions from './actions'
import * as types from './types'

function* handleFetchFirmwares() {
  const firmwares = yield call([api.firmwares, api.firmwares.getAll])
  yield put(actions.fetchedFirmwares(firmwares))
}

function* handleDeleteFirmware({ payload: id }) {
  yield call([api.firmwares, api.firmwares.delete], id)
  yield put(actions.deleteFirmware(id))
  yield put(notifySuccess('Прошивка удалена'))
}

function* main() {
  yield takeLeading(types.FETCH_FIRMWARES, handleFetchFirmwares)
  yield takeLeading(types.DELETE_FIRMWARE, handleDeleteFirmware)
}

export default main
