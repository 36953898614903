class HttpResponseError extends Error {
  constructor(data, status) {
    super()
    this.name = 'HttpResponseError'
    this.data = data
    this.status = status
  }
}

const JSON_REGEX = /^\s*application\/json\s*(;.*)?$/

function isJSONBody(body) {
  if (!body) {
    return false
  }
  return Array.isArray(body) || body.constructor === Object
}

function createRequestConfig(method, params = {}, headers) {
  const opts = Object.assign({}, params)
  if (opts.body && isJSONBody(opts.body)) {
    opts.body = JSON.stringify(opts.body)
    headers['Content-Type'] = 'application/json'
  }
  return Object.assign(
    {
      method: method,
      headers: headers,
    },
    opts,
  )
}

class Http {
  constructor({ baseUrl, logger } = {}) {
    if (typeof baseUrl !== 'string') {
      throw new TypeError('Missing baseUrl param in Http constructor')
    }
    this._baseUrl = baseUrl
    this._logger = logger
  }

  _getRequestHeaders() {
    return {
      Accept: 'application/json',
    }
  }

  async _handleResponse(response) {
    let data = {}

    if (response.status === 204) {
      return Promise.resolve(data)
    }

    const contentType = response.headers.get('content-type')
    const isJSON = JSON_REGEX.test(contentType)

    if (isJSON) {
      data = await response.json()
    } else {
      data = await response.text()
    }

    if (!response.ok) {
      throw new HttpResponseError(data, response.status)
    }

    return data
  }

  createUri(path) {
    return this._baseUrl + path
  }

  async request(method, path, params) {
    const _headers = await this._getRequestHeaders()
    const config = createRequestConfig(method, params, _headers)
    const url = this.createUri(path)
    this._logger.debug(`${method} ${url}`, params, _headers)
    return fetch(url, config).then(this._handleResponse)
  }

  get(path) {
    return this.request('GET', path)
  }

  post(path, body) {
    return this.request('POST', path, { body })
  }

  put(path, body) {
    return this.request('PUT', path, { body })
  }

  patch(path, body) {
    return this.request('PATCH', path, { body })
  }

  delete(path) {
    return this.request('DELETE', path)
  }
}

export default Http
