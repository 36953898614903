import * as Yup from 'yup'

export const Email = Yup.string()
  .typeError('Некорректный емейл')
  .email('Некорректный емейл')
  .required('Емейл не может быть пустым')

export const Password = Yup.string()
  .typeError('Некорректный пароль')
  .required('Пароль не может быть пустым')
