import * as Page from '../pages'

function getAdminRoutes() {
  return [
    {
      label: 'Клиеты',
      path: '/',
      exact: true,
      component: Page.Admin.Organizations,
    },
    {
      label: 'Прошивки',
      path: '/firmwares',
      component: Page.Admin.Firmwares,
    },
  ]
}

function getDefaultRoutes(userRole) {
  const routes = [
    {
      label: 'Галлерея',
      path: '/',
      exact: true,
      component: Page.Default.Assets,
    },
    {
      label: 'История',
      path: '/history',
      component: Page.Default.Content,
    },
    // {
    //   label: 'Устройства',
    //   path: '/devices',
    //   component: Page.Default.Devices,
    // },
  ]
  return routes
}

function getRoutes(userRole) {
  return getDefaultRoutes(userRole)
}

export default getRoutes
