import { createAction } from 'redux-actions'
import * as types from './types'

export * from './assets/actions'
export * from './content/actions'
export * from './devices/actions'
export * from './employees/actions'

export const fetchOrganization = createAction(types.FETCH_ORGANIZATION)
export const fetchedOrganization = createAction(
  types.FETCH_ORGANIZATION_SUCCESS,
)
