import { handleActions } from 'redux-actions'
import { findByIdAndChange, bulkRemoveItemByIds, removeItemById } from 'lib'
import * as types from './types'

const initialState = {
  list: null,
  uploading: false,
}

const handlers = {
  [types.FETCH_ASSETS_SUCCESS]: (state, action) => ({
    ...state,
    list: action.payload,
    uploading: false,
  }),
  [types.ADD_ASSETS_UPLOAD]: state => ({
    ...state,
    uploading: true,
  }),
  [types.ADD_ASSET_SUCCESS]: (state, action) => ({
    ...state,
    list: [action.payload, ...state.list],
    uploading: false,
  }),
  [types.EDIT_ASSET_SUCCESS]: (state, action) => ({
    ...state,
    list: findByIdAndChange(action, state.list),
  }),
  [types.REMOVE_ASSET_SUCCESS]: (state, action) => ({
    ...state,
    list: removeItemById(action, state.list),
  }),
  [types.BULK_REMOVE_ASSETS_SUCCESS]: (state, action) => ({
    ...state,
    list: bulkRemoveItemByIds(action, state.list),
  }),
}

export default handleActions(handlers, initialState)
