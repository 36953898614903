import { handleActions } from 'redux-actions'
import * as types from './types'

const initialState = {
  user: null,
  loading: true,
  signingIn: false,
}

const reducer = handleActions(
  {
    [types.SIGN_IN]: state => ({
      ...state,
      signingIn: true,
    }),
    [types.SIGN_IN_SUCCESS]: (state, action) => ({
      ...state,
      user: action.payload,
      loading: false,
      signingIn: false,
    }),
    [types.SIGN_IN_ERROR]: state => ({
      ...state,
      loading: false,
      signingIn: false,
    }),
    [types.SIGN_OUT]: state => ({
      ...state,
      user: null,
      loading: false,
      signingIn: false,
    }),
  },
  initialState,
)

export default reducer
