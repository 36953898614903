import { call, put, takeLeading } from 'redux-saga/effects'
import api from 'api'
import { withErrorHandler } from 'lib'
import * as actions from './actions'
import * as types from './types'

function* checkSignedIn() {
  try {
    const hasCredentials = yield call([api.auth, api.auth.hasCredentials])
    if (!hasCredentials) {
      yield put(actions.signOut())
      return
    }
    const user = yield call([api.users, api.users.getCurrentProfile])
    if (user) {
      yield put(actions.signedIn(user))
    } else {
      yield put(actions.signOut())
    }
  } catch (err) {
    yield put(actions.signOut())
  }
}

function* handleSignIn({ payload: credentials }) {
  try {
    const user = yield call([api.auth, api.auth.login], credentials)
    yield put(actions.signedIn(user))
  } catch (err) {
    yield put(actions.signInError())
    if (err.status === 401) {
      err.message = 'Неправильный емейл или пароль'
    }
    throw err
  }
}

function* handleSignOut() {
  yield call([api.auth, api.auth.logout])
}

function* main() {
  yield takeLeading(types.CHECK_ACCESS, checkSignedIn)
  yield takeLeading(types.SIGN_IN, withErrorHandler(handleSignIn))
  yield takeLeading(types.SIGN_OUT, withErrorHandler(handleSignOut))
}

export default main
