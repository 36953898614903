import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { Box, Grid } from 'grommet'
import { signOut } from 'modules/account'
import { fetchOrganization, getOrganizationName } from 'modules/organization'
import NavBar from './NavBar'

const gridConfig = ['auto', 'flex']
const areaConfig = [
  { name: 'header', start: [0, 0], end: [1, 0] },
  { name: 'main', start: [1, 1], end: [1, 1] },
]

const propTypes = {
  children: PropTypes.node.isRequired,
  navBarRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  signOut: PropTypes.func.isRequired,
}

class HomeLayout extends React.PureComponent {
  componentDidMount() {
    const { fetchOrganization } = this.props
    fetchOrganization()
  }

  render() {
    const { children, navBarRoutes, organization, signOut } = this.props
    let title = `Pix Бизнес`
    if (organization) {
      title = `${organization} — ${title}`
    }
    return (
      <Grid fill rows={gridConfig} columns={gridConfig} areas={areaConfig}>
        <Helmet title={title} />
        <NavBar
          title={organization}
          routes={navBarRoutes}
          onSignOut={signOut}
        />
        <Box gridArea="main">{children}</Box>
      </Grid>
    )
  }
}

HomeLayout.propTypes = propTypes

function mapState(state) {
  return {
    organization: getOrganizationName(state),
  }
}

const mapDispatch = {
  fetchOrganization,
  signOut,
}

export default connect(
  mapState,
  mapDispatch,
)(HomeLayout)
