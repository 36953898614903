import { handleActions } from 'redux-actions'
import { removeItemById } from 'lib'
import * as types from './types'

const initialState = {
  list: [],
}

const handlers = {
  [types.FETCH_FIRMWARES_SUCCESS]: (state, action) => ({
    ...state,
    list: action.payload,
  }),
  [types.DELETE_FIRMWARE_SUCCESS]: (state, action) => ({
    ...state,
    list: removeItemById(action, state.list),
  }),
}

export default handleActions(handlers, initialState)
