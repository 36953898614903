import { createAction } from 'redux-actions'
import * as types from './types'

export const fetchAssets = createAction(types.FETCH_ASSETS)
export const fetchedAssets = createAction(types.FETCH_ASSETS_SUCCESS)

export const addAssets = createAction(types.ADD_ASSETS)
export const assetsUpload = createAction(types.ADD_ASSETS_UPLOAD)
export const addedAsset = createAction(types.ADD_ASSET_SUCCESS)

export const editAsset = createAction(types.EDIT_ASSET)
export const editedAsset = createAction(types.EDIT_ASSET_SUCCESS)

export const removeAsset = createAction(types.REMOVE_ASSET)
export const removedAsset = createAction(types.REMOVE_ASSET_SUCCESS)

export const removeMultipleAssets = createAction(types.BULK_REMOVE_ASSETS)
export const removedMultipleAssets = createAction(
  types.BULK_REMOVE_ASSETS_SUCCESS,
)
