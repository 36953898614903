import Http from './Http'

class AuthHttp extends Http {
  constructor(config) {
    super(config)
    this._storage = config.storage
  }

  async _getRequestHeaders() {
    const headers = super._getRequestHeaders()
    const token = await this._storage.getItem('token')
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    return headers
  }
}

export default AuthHttp
