import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request'
import endpoints from './endpoints'
import { AuthHttp, Http } from './http'
import { BaseLogger, BaseStorage } from './interfaces'

const STORAGE_ERROR_MESSAGE = [
  'PixBusinessApiClient is missing PixBusinessApiClient.BaseStorage',
  'implementation as the second parameter in the constructor.',
].join(' ')

class VoidLogger extends BaseLogger {
  log() {}
  info() {}
  warn() {}
  debug() {}
  error() {}
}

class PixBusinessApiClient {
  constructor({ baseUrl, logger, storage }) {
    if (!storage || !(storage instanceof BaseStorage)) {
      throw new TypeError(STORAGE_ERROR_MESSAGE)
    }
    if (!logger) {
      logger = new VoidLogger()
    }
    this._logger = logger
    this._storage = storage
    this._http = new Http({ baseUrl, logger })
    this._authHttp = new AuthHttp({ baseUrl, logger, storage })
    this.initEndpoints()
  }

  initEndpoints() {
    this.auth = new endpoints.AuthEndpoint({
      http: this._http,
      path: 'auth',
      storage: this._storage,
    })
    this.users = new endpoints.UsersEndpoint({
      http: this._authHttp,
      path: 'users',
    })
    this.organizations = new endpoints.RestEndpoint({
      http: this._authHttp,
      path: 'organizations',
    })
    this.cities = new endpoints.RestEndpoint({
      http: this._authHttp,
      path: 'cities',
    })
    this.devices = new endpoints.RestEndpoint({
      http: this._authHttp,
      path: 'devices',
    })
    this.assets = new endpoints.AssetsEndpoint({
      http: this._authHttp,
      path: 'assets',
    })
    this.firmwares = new endpoints.FileUploadRestEndpoint({
      http: this._authHttp,
      path: 'firmwares',
    })
    this.contentPresets = new endpoints.ContentPresetsEndpoint({
      http: this._authHttp,
      path: 'content-preset',
      createAssetThumbnailUrl: this.assets.getFileUrl.bind(this.assets),
    })
  }
}

PixBusinessApiClient.BaseStorage = BaseStorage
PixBusinessApiClient.RequestQueryBuilder = RequestQueryBuilder
PixBusinessApiClient.CondOperator = CondOperator

export default PixBusinessApiClient
