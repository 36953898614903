import { call, put, select, takeLeading } from 'redux-saga/effects'
import { RequestQueryBuilder } from '@nestjsx/crud-request'
import api from 'api'
import { notifySuccess } from 'modules/notifications'
import { getOrganization } from '../selectors'
import * as actions from './actions'
import * as types from './types'

function* handleFetchDevices() {
  const query = RequestQueryBuilder.create({
    join: [{ field: 'devices' }],
  }).query()
  const { id } = yield select(getOrganization)
  const organization = yield call(
    [api.organizations, api.organizations.getOne],
    id,
    query,
  )
  const { devices } = organization
  yield put(actions.fetchedDevices(devices))
}

function* handleEditDevice({ payload: { id: itemId, changes } }) {
  yield call([api.devices, api.devices.update], {
    itemId,
    changes,
  })
  yield put(actions.editedDevice({ id: itemId, changes }))
  yield put(notifySuccess('Ваши изменения сохранены'))
}

function* main() {
  yield takeLeading(types.FETCH_DEVICES, handleFetchDevices)
  yield takeLeading(types.EDIT_DEVICE, handleEditDevice)
}

export default main
