import React from 'react'
import { Button, Box, Heading } from 'grommet'
import { StatusWarning } from 'grommet-icons'

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    console.error(error, info)
  }

  reload = () => {
    window.location.reload()
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state
    if (!hasError) {
      return children
    }
    return (
      <Box fill justify="center" align="center">
        <StatusWarning size="xlarge" color="status-critical" />
        <Box pad="medium">
          <Heading level={3} textAlign="center">
            Что-то пошло не так. Пожалуйста, перезагрузите страницу
          </Heading>
        </Box>
        <Button
          label="Перезагрузить"
          primary
          color="status-critical"
          onClick={this.reload}
        />
      </Box>
    )
  }
}

export default ErrorBoundary
