import React from 'react'
import { connect } from 'react-redux'
import { Box, Button, DataTable, Layer, Text } from 'grommet'
import { Trash } from 'grommet-icons'
import { fetchFirmwares, deleteFirmware, getFirmwares } from 'modules/admin'
import { ConfirmationDialog } from 'components'
import NewFirmwareForm from './NewFirmwareForm'

class Firmwares extends React.Component {
  state = {
    firmwareToDelete: null,
    newFirmwareFormVisible: false,
  }

  componentDidMount() {
    const { fetchFirmwares } = this.props
    fetchFirmwares()
  }

  showNewFirmwareForm = () => {
    this.setState({ newFirmwareFormVisible: true })
  }

  hideNewFirmwareForm = () => {
    this.setState({ newFirmwareFormVisible: false })
  }

  confirmFirmwareDelete = firmware => {
    this.setState({ firmwareToDelete: firmware })
  }

  deleteFirmware = () => {
    const { deleteFirmware } = this.props
    const { firmwareToDelete } = this.state
    deleteFirmware(firmwareToDelete.id)
    this.setState({ firmwareToDelete: null })
  }

  cancelFirmwareDelete = () => {
    this.setState({ firmwareToDelete: null })
  }

  tableConfig = [
    {
      property: 'version',
      header: <Text>Версия</Text>,
      primary: true,
      search: true,
    },
    {
      property: 'checksum',
      header: <Text>Контрольная сумма</Text>,
    },
    {
      property: 'size',
      header: <Text>Размер</Text>,
    },
    {
      property: '—',
      header: (
        <Button label="Новая прошивка" onClick={this.showNewFirmwareForm} />
      ),
      render: firmware => (
        <Button
          icon={<Trash />}
          onClick={() => this.confirmFirmwareDelete(firmware)}
          disabled
        />
      ),
      align: 'center',
      sortable: false,
    },
  ]

  render() {
    const { firmwares } = this.props
    const { firmwareToDelete, newFirmwareFormVisible } = this.state
    return (
      <Box pad="medium">
        <DataTable
          columns={this.tableConfig}
          data={firmwares}
          size="xlarge"
          sortable
        />
        {firmwareToDelete && (
          <ConfirmationDialog
            title="Удалить прошивку"
            message={`Вы уверены, что хотите удалить прошивку ${firmwareToDelete.version}?`}
            confirmLabel="Удалить"
            onConfirm={this.deleteFirmware}
            onCancel={this.cancelFirmwareDelete}
          />
        )}
        {newFirmwareFormVisible && (
          <Layer
            modal
            position="center"
            onClickOutside={this.hideNewFirmwareFormVisible}
            onEsc={this.hideNewFirmwareFormVisible}
          >
            <NewFirmwareForm onClose={this.hideNewFirmwareForm} />
          </Layer>
        )}
      </Box>
    )
  }
}

function mapState(state) {
  return {
    firmwares: getFirmwares(state),
  }
}

const mapDispatch = {
  fetchFirmwares,
  deleteFirmware,
}

export default connect(
  mapState,
  mapDispatch,
)(Firmwares)
