import { handleActions } from 'redux-actions'
import { findByIdAndChange, removeItemById } from 'lib'
import * as types from './types'

const initialState = {
  list: [],
}

const handlers = {
  [types.FETCH_EMPLOYEES_SUCCESS]: (state, action) => ({
    ...state,
    list: action.payload,
  }),
  [types.ADD_EMPLOYEE_SUCCESS]: (state, action) => ({
    ...state,
    list: [...state.list, action.payload],
  }),
  [types.EDIT_EMPLOYEE_SUCCESS]: (state, action) => ({
    ...state,
    list: findByIdAndChange(action, state.list),
  }),
  [types.REMOVE_EMPLOYEE_SUCCESS]: (state, action) => ({
    ...state,
    list: removeItemById(action, state.list),
  }),
}

export default handleActions(handlers, initialState)
