import React from 'react'
import { Box, Button, Heading, Layer, Text } from 'grommet'

class Alert extends React.Component {
  render() {
    const { title, message, onClose } = this.props
    return (
      <Layer position="center" modal onClickOutside={onClose} onEsc={onClose}>
        <Box pad="medium" gap="small" width="medium">
          <Heading level={3} margin="none">
            {title}
          </Heading>
          <Text>{message}</Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button
              label={
                <Text color="white">
                  <strong>Окей</strong>
                </Text>
              }
              onClick={onClose}
              primary
            />
          </Box>
        </Box>
      </Layer>
    )
  }
}

export default Alert
