import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Box, Button, FormField, Heading, TextInput } from 'grommet'
import { Close } from 'grommet-icons'

function mapPropsToValues(props) {
  return {
    title: props.asset.title,
  }
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Название не может быть пустым'),
})

function handleSubmit(values, { props }) {
  props.onSubmit(values)
}

class EditAssetForm extends React.Component {
  onFieldChange = event => {
    const { setFieldValue } = this.props
    setFieldValue(event.target.name, event.target.value)
  }

  onKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.props.submitForm()
    }
  }

  render() {
    const { values, errors, submitForm, onClose } = this.props
    return (
      <Box
        as="form"
        fill="vertical"
        overflow="auto"
        width="medium"
        pad="medium"
      >
        <Box flex={false} direction="row" justify="between">
          <Heading level={2} margin="none">
            Редактировать
          </Heading>
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        <Box flex="grow" overflow="auto" pad={{ vertical: 'medium' }}>
          <FormField label="Название" error={errors.title}>
            <TextInput
              autoFocus
              name="title"
              value={values.title}
              onChange={this.onFieldChange}
              onKeyPress={this.onKeyPress}
            />
          </FormField>
        </Box>
        <Box flex={false} as="footer" align="start">
          <Button
            label="Сохранить"
            primary
            onClick={submitForm}
            disabled={values.title.length <= 0}
          />
        </Box>
      </Box>
    )
  }
}

export default withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(EditAssetForm)
