import React from 'react'
import { Provider } from 'react-redux'
import { Grommet } from 'grommet'
import Router from './router'
import store from './store'

function App() {
  return (
    <Provider store={store()}>
      <Grommet full>
        <Router />
      </Grommet>
    </Provider>
  )
}

export default App
