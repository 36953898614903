import { call, delay, put, takeLeading } from 'redux-saga/effects'
import { format } from 'date-fns'
import api from 'api'
import { notifySuccess, notifyError } from 'modules/notifications'
import * as actions from './actions'
import * as types from './types'

function* handleFetchContent() {
  const content = yield call([api.contentPresets, api.contentPresets.getAll])
  yield put(actions.fetchedContent(content))
}

function createTimestamp() {
  return format(new Date(), 'dd-MM-yyyy HH:mm')
}

function* handleContentPackUpload({ payload: assetIdList }) {
  try {
    const contentPack = yield call(
      [api.contentPresets, api.contentPresets.add],
      {
        name: createTimestamp(),
        assets: assetIdList.map(id => ({ id })),
      },
    )
    yield call([api.contentPresets, api.contentPresets.build], contentPack.id)
    const assetsCount = assetIdList.length
    yield put(actions.contentBuildComplete())
    yield put(notifySuccess(`Начата установка ${assetsCount} изображений`))
  } catch (err) {
    const message =
      err.status === 413
        ? 'Анимация содержит сликшом много цветов или кадров'
        : 'Что-то пошло не так, пожалуйста попробуйте позже'
    yield put(notifyError(message))
  } finally {
    yield delay(1000)
    yield put(actions.resetBuildStatus())
  }
}

function* main() {
  yield takeLeading(types.FETCH_CONTENT, handleFetchContent)
  yield takeLeading(types.BUILD_CONTENT, handleContentPackUpload)
}

export default main
