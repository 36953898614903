function throwOverrideError(methodName) {
  throw new TypeError([
    `Override ${methodName} method`,
    'in your BaseStorage implementation',
  ])
}

class BaseStorage {
  getItem(key) {
    throwOverrideError('getItem')
  }

  setItem(key, value) {
    throwOverrideError('setItem')
  }

  removeItem(key) {
    throwOverrideError('removeItem')
  }

  clear() {
    throwOverrideError('clear')
  }
}

export default BaseStorage
