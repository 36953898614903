import React from 'react'
import { Box, Button, Layer, Text } from 'grommet'
import { FormClose, StatusCritical, StatusGood } from 'grommet-icons'

class Toast extends React.Component {
  render() {
    const { type, message, onClose } = this.props
    if (!type || !message) {
      return null
    }
    const isError = type === 'error'
    return (
      <Layer
        position="bottom"
        modal={false}
        margin={{ vertical: 'medium', horizontal: 'small' }}
        onEsc={onClose}
        responsive={false}
        plain
      >
        <Box
          align="center"
          direction="row"
          gap="small"
          justify="between"
          round="medium"
          elevation="medium"
          pad={{ vertical: 'xsmall', horizontal: 'small' }}
          background={isError ? 'status-critical' : 'status-ok'}
        >
          <Box align="center" direction="row" gap="xsmall">
            {isError ? <StatusCritical /> : <StatusGood />}
            <Text>{message}</Text>
          </Box>
          <Button icon={<FormClose />} onClick={onClose} plain />
        </Box>
      </Layer>
    )
  }
}

export default Toast
