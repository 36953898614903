import { delay, takeLeading, put } from 'redux-saga/effects'
import * as actions from './actions'
import * as types from './types'

function* hideModalOnTimeout({ payload: notification }) {
  if (Number.isFinite(notification.hideTimeout)) {
    yield delay(notification.hideTimeout)
    yield put(actions.hide())
  }
}

function* main() {
  yield takeLeading(types.SHOW, hideModalOnTimeout)
}

export default main
