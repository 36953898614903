import PixApiClient from 'pix-b2b-api-client'
import { Storage } from 'lib'

console.log('PixApiClient', PixApiClient)
class NamespaceStorage extends PixApiClient.BaseStorage {
  constructor({ namespace = '', backend = Storage } = {}) {
    super()
    this._prefix = namespace
    this._backend = backend
  }

  getItem(key) {
    return this._backend.getItem(this._prefix + key)
  }

  setItem(key, value) {
    this._backend.setItem(this._prefix + key, value)
  }

  removeItem(key) {
    this._backend.removeItem(this._prefix + key)
  }

  clear() {
    this._backend.clear()
  }
}

const storage = new NamespaceStorage({
  namespace: 'pix_api_',
})

export const { RequestQueryBuilder, CondOperator } = PixApiClient

export default new PixApiClient({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  storage,
})
