import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'grommet'

const DEVICE_STATUS_MAP = {
  waiting: {
    title: 'Ожидает',
    color: 'neutral-3',
  },
  updating: {
    title: 'Обновляется',
    color: 'status-unknown',
  },
  updated: {
    title: 'Обновлено',
    color: 'status-ok',
  },
  failed: {
    title: 'Ошибка',
    color: 'status-error',
  },
}

const propTypes = {
  status: PropTypes.oneOf(Object.keys(DEVICE_STATUS_MAP)).isRequired,
}

const defaultProps = {
  status: 'updated',
}

class DeviceStatusLabel extends React.PureComponent {
  render() {
    const { status } = this.props
    const { title, color } = DEVICE_STATUS_MAP[status]
    return (
      <Text color={color} size="small" weight="bold">
        {title}
      </Text>
    )
  }
}

DeviceStatusLabel.propTypes = propTypes
DeviceStatusLabel.defaultProps = defaultProps

export default DeviceStatusLabel
