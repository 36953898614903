function throwOverrideError(methodName) {
  throw new TypeError(
    `Override ${methodName} method in your BaseLogger implementation`,
  )
}

class BaseLogger {
  log() {
    throwOverrideError('log')
  }

  info() {
    throwOverrideError('info')
  }

  warn() {
    throwOverrideError('warn')
  }

  debug() {
    throwOverrideError('debug')
  }

  error() {
    throwOverrideError('error')
  }
}

export default BaseLogger
