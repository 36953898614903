export const FETCH_ASSETS = 'organization/assets/FETCH'
export const FETCH_ASSETS_SUCCESS = 'organization/assets/FETCH_SUCCESS'

export const ADD_ASSETS = 'organization/assets/ADD'
export const ADD_ASSETS_UPLOAD = 'organization/assets/ASSETS_UPLOAD'
export const ADD_ASSET_SUCCESS = 'organization/assets/ADD_SUCCESS'

export const EDIT_ASSET = 'organization/assets/EDIT'
export const EDIT_ASSET_SUCCESS = 'organization/assets/EDIT_SUCCESS'

export const REMOVE_ASSET = 'organization/assets/REMOVE'
export const REMOVE_ASSET_SUCCESS = 'organization/assets/REMOVE_SUCCESS'

export const BULK_REMOVE_ASSETS = 'organization/assets/BULK_REMOVE'
export const BULK_REMOVE_ASSETS_SUCCESS =
  'organization/assets/BULK_REMOVE_SUCCESS'
