import React from 'react'
import { connect } from 'react-redux'
import { Box, Button, DataTable, Layer, Text } from 'grommet'
import {
  addOrganization,
  editOrganization,
  fetchOrganizations,
  getOrganizations,
} from 'modules/admin'
import NewOrganizationForm from './NewOrganizationForm'

class Organizations extends React.Component {
  state = {
    newOrganizationFormVisible: false,
  }

  componentDidMount() {
    const { fetchOrganizations } = this.props
    fetchOrganizations()
  }

  showNewOrganizationForm = () => {
    this.setState({ newOrganizationFormVisible: true })
  }

  hideNewOrganizationForm = () => {
    this.setState({ newOrganizationFormVisible: false })
  }

  tableConfig = [
    {
      property: 'name',
      header: <Text>Организация</Text>,
      primary: true,
      search: true,
    },
    {
      property: '—',
      header: <Text>Устройства</Text>,
      render: () => <Text>N/A</Text>,
      align: 'center',
    },
    {
      property: '—',
      header: <Text>Загрузок</Text>,
      render: () => <Text>N/A</Text>,
      align: 'center',
    },
    {
      property: '—',
      header: <Text>Последнняя загрузка</Text>,
      render: () => <Text>N/A</Text>,
      align: 'center',
    },
    {
      property: '—',
      header: (
        <Button
          label="Добавить клиента"
          onClick={this.showNewOrganizationForm}
        />
      ),
      align: 'center',
      sortable: false,
    },
  ]

  render() {
    const { organizations } = this.props
    const { newOrganizationFormVisible } = this.state
    return (
      <Box pad="medium">
        <DataTable
          columns={this.tableConfig}
          data={organizations}
          size="xlarge"
          sortable
        />
        {newOrganizationFormVisible && (
          <Layer
            position="right"
            full="vertical"
            modal
            onClickOutside={this.hideNewOrganizationForm}
            onEsc={this.hideNewOrganizationForm}
          >
            <NewOrganizationForm onClose={this.hideNewOrganizationForm} />
          </Layer>
        )}
      </Box>
    )
  }
}

function mapState(state) {
  return {
    organizations: getOrganizations(state),
  }
}

const mapDispatch = {
  addOrganization,
  editOrganization,
  fetchOrganizations,
}

export default connect(
  mapState,
  mapDispatch,
)(Organizations)
