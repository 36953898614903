import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import { RequestQueryBuilder } from '@nestjsx/crud-request'
import api from 'api'
import { notifySuccess, showAlert } from 'modules/notifications'
import { ImageValidator } from 'lib'
import { getOrganization } from '../selectors'
import * as actions from './actions'
import * as types from './types'

function* handleFetchAssets() {
  const query = RequestQueryBuilder.create({
    join: [{ field: 'assets' }],
  }).query()
  const { id } = yield select(getOrganization)
  const organization = yield call(
    [api.organizations, api.organizations.getOne],
    id,
    query,
  )
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  const assets = organization.assets.map(asset => ({
    ...asset,
    thumbnailUrl: `${baseUrl}/assets/${asset.id}/file`,
  }))
  yield put(actions.fetchedAssets(assets))
}

function* handleAddAssets({ payload: files }) {
  const validator = new ImageValidator()
  const validate = files.map(file => call([validator, validator.isValid], file))
  const result = yield all(validate)
  const validFiles = result.filter(res => res.valid).map(res => res.file)
  const hasValidFiles = validFiles.length > 0
  const invalidFileNames = result
    .filter(res => !res.valid)
    .map(res => res.file.name)
  if (invalidFileNames.length > 0) {
    const names = invalidFileNames.join(', ')
    const notification = {
      title: 'Загрузка',
    }
    notification.message = [
      `Невозможно загрузить файлы ${names}.`,
      'Убедитесь, что вы загружаете PNG или GIF',
      'изображения размером 22х22 пикселя.',
    ]
    if (hasValidFiles) {
      notification.message.push('\n\nОстальные изображения загружаются.')
    }
    notification.message = notification.message.join(' ')
    yield put(showAlert(notification))
  }
  if (hasValidFiles) {
    yield put(actions.assetsUpload())
    yield call([api.assets, api.assets.addMany], validFiles)
    yield call(handleFetchAssets)
  }
}

function* handleEditAsset({ payload: { id: itemId, changes } }) {
  yield call([api.assets, api.assets.update], {
    itemId,
    changes,
  })
  yield put(actions.editedAsset({ id: itemId, changes }))
  yield put(notifySuccess('Ваши изменения сохранены'))
}

function* handleRemoveAsset({ payload: assetId }) {
  yield call([api.assets, api.assets.delete], assetId)
  yield put(actions.removedAsset(assetId))
  yield put(notifySuccess('Изображение удалено'))
}

function* handleBulkRemoveAsset({ payload: assetIdList }) {
  const count = assetIdList.length
  const remove = assetIdList.map(id =>
    call([api.assets, api.assets.delete], id),
  )
  yield all(remove)
  yield put(actions.removedMultipleAssets(assetIdList))
  yield put(notifySuccess(`Удалено ${count} изобращений`))
}

function* main() {
  yield takeLeading(types.FETCH_ASSETS, handleFetchAssets)
  yield takeLeading(types.ADD_ASSETS, handleAddAssets)
  yield takeLeading(types.EDIT_ASSET, handleEditAsset)
  yield takeLeading(types.REMOVE_ASSET, handleRemoveAsset)
  yield takeLeading(types.BULK_REMOVE_ASSETS, handleBulkRemoveAsset)
}

export default main
