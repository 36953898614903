import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Menu } from 'grommet'
import { getCities } from 'modules/organization'

const propTypes = {
  city: PropTypes.string,
  onSelect: PropTypes.func,
}

const defaultProps = {
  city: '',
  onSelect: () => {},
}

function CitySelector({ cities, city, onSelect }) {
  function cityToOption(city) {
    return {
      label: city,
      onClick: () => onSelect(city),
    }
  }

  return <Menu label={city} items={cities.map(cityToOption)} />
}

CitySelector.propTypes = propTypes
CitySelector.defaultProps = defaultProps

function mapState(state) {
  return {
    cities: getCities(state),
  }
}

export default connect(mapState)(CitySelector)
