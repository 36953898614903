import { handleActions } from 'redux-actions'
import * as types from './types'

const initialState = {
  type: null,
  message: null,
}

const reducer = handleActions(
  {
    [types.SHOW]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [types.HIDE]: () => initialState,
  },
  initialState,
)

export default reducer
