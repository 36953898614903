import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducer from './reducer'
import saga from './saga'

const reduxSaga = createSagaMiddleware()
const middlewares = [reduxSaga]

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(require('redux-logger').default)
}

export default function configureStore() {
  const store = createStore(reducer, applyMiddleware(...middlewares))
  reduxSaga.run(saga)
  return store
}
