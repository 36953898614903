import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Box, Button, Text } from 'grommet'

function Route({ label, path, current }) {
  return (
    <Link to={path}>
      <Button pad="small" align="center">
        <Text color="light-1" weight={current ? 'bold' : 'normal'}>
          {label}
        </Text>
      </Button>
    </Link>
  )
}

function NavBar({ routes, title, onSignOut, location, ...rest }) {
  const { pathname } = location
  return (
    <Box
      gridArea="header"
      direction="row"
      align="center"
      justify="between"
      pad={{ horizontal: 'medium', vertical: 'small' }}
      background="brand"
    >
      <Button>
        <Text size="large" weight="bold">
          {title}
        </Text>
      </Button>
      <Box direction="row" gap="small">
        {routes.map(route => (
          <Route
            key={route.path}
            {...route}
            current={pathname === route.path}
          />
        ))}
      </Box>
      <Button onClick={onSignOut}>
        <Text>Выйти</Text>
      </Button>
    </Box>
  )
}

export default withRouter(NavBar)
