import { call, put, spawn, select, takeLeading } from 'redux-saga/effects'
import { RequestQueryBuilder } from '@nestjsx/crud-request'
import api from 'api'
import assets from './assets/saga'
import content from './content/saga'
import devices from './devices/saga'
import employees from './employees/saga'
import { getOrganization } from './selectors'
import * as actions from './actions'
import * as types from './types'

function* handleFetchOrganization() {
  const query = RequestQueryBuilder.create({
    join: [{ field: 'cities' }],
  }).query()
  const { id } = yield select(getOrganization)
  const org = yield call(
    [api.organizations, api.organizations.getOne],
    id,
    query,
  )
  const cities = org.cities.map(city => city.name)
  yield put(
    actions.fetchedOrganization({
      ...org,
      cities,
    }),
  )
}

function* main() {
  yield takeLeading(types.FETCH_ORGANIZATION, handleFetchOrganization)
  yield spawn(assets)
  yield spawn(content)
  yield spawn(devices)
  yield spawn(employees)
}

export default main
