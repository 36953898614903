import { all, spawn } from 'redux-saga/effects'
import { accountSaga } from 'modules/account'
import { adminSaga } from 'modules/admin'
import { notificationsSaga } from 'modules/notifications'
import { organizationSaga } from 'modules/organization'

export default function*() {
  yield all([
    spawn(accountSaga),
    spawn(adminSaga),
    spawn(notificationsSaga),
    spawn(organizationSaga),
  ])
}
