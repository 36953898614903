import { createAction } from 'redux-actions'
import * as types from './types'

export const notifyError = createAction(types.SHOW, message => ({
  type: 'error',
  message,
  hideTimeout: 2000,
}))

export const notifySuccess = createAction(types.SHOW, message => ({
  type: 'success',
  message,
  hideTimeout: 2000,
}))

export const showAlert = createAction(types.SHOW, ({ title, message }) => ({
  type: 'alert',
  title,
  message,
  hideTimeout: null,
}))

export const hide = createAction(types.HIDE)
