import React from 'react'
import { Box, Button, Heading, Layer, Text } from 'grommet'

class ConfirmatonDialog extends React.Component {
  componentDidMount() {
    window.addEventListener('keypress', this.onKeyPress)
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.onKeyPress)
  }

  onKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.props.onConfirm()
    }
  }

  render() {
    const { title, message, confirmLabel, onConfirm, onCancel } = this.props
    return (
      <Layer position="center" modal onClickOutside={onCancel} onEsc={onCancel}>
        <Box pad="medium" gap="small" width="medium">
          <Heading level={3} margin="none">
            {title}
          </Heading>
          <Text>{message}</Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button label="Отмена" onClick={onCancel} color="dark-3" />
            <Button
              label={
                <Text color="white">
                  <strong>{confirmLabel}</strong>
                </Text>
              }
              onClick={onConfirm}
              primary
              color="status-critical"
            />
          </Box>
        </Box>
      </Layer>
    )
  }
}

export default ConfirmatonDialog
