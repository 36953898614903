import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Box, Button, FormField, Heading, TextInput } from 'grommet'
import { Email } from 'validation'
import { Close } from 'grommet-icons'
import { addOrganization } from 'modules/admin'

function mapPropsToValues() {
  return {
    organization: {
      name: '',
    },
    owner: {
      email: '',
      name: '',
    },
  }
}

const validationSchema = Yup.object().shape({
  organization: Yup.object().shape({
    name: Yup.string().required('Название не может быть пустым'),
  }),
  owner: Yup.object().shape({
    email: Email,
    firstName: Yup.string().required('Имя не может быть пустым'),
    lastName: Yup.string().required('Фамилия не может быть пустой'),
  }),
})

function handleSubmit(values, { props }) {
  props.addOrganization(values)
  props.onClose()
}

class NewEmployeeForm extends React.Component {
  onFieldChange = event => {
    const { setFieldValue } = this.props
    setFieldValue(event.target.name, event.target.value)
  }

  render() {
    const { values, errors, submitForm, onClose } = this.props
    return (
      <Box
        as="form"
        fill="vertical"
        overflow="auto"
        width="medium"
        pad="medium"
      >
        <Box flex={false} direction="row" justify="between">
          <Heading level={2} margin="none">
            Новый клиент
          </Heading>
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        <Box flex="grow" overflow="auto" pad={{ vertical: 'medium' }}>
          <Heading level={3} margin="small">
            Организация
          </Heading>
          <FormField
            label="Название"
            error={errors.organization ? errors.organization.name : null}
          >
            <TextInput
              name="organization.name"
              value={values.organization.name}
              onChange={this.onFieldChange}
            />
          </FormField>
          <Heading level={3} margin="small">
            Владелец
          </Heading>
          <FormField
            label="Имя владельца"
            error={errors.owner ? errors.owner.firstName : null}
          >
            <TextInput
              name="owner.firstName"
              value={values.owner.firstName}
              onChange={this.onFieldChange}
            />
          </FormField>
          <FormField
            label="Фамилия владельца"
            error={errors.owner ? errors.owner.lastName : null}
          >
            <TextInput
              name="owner.lastName"
              value={values.owner.lastName}
              onChange={this.onFieldChange}
            />
          </FormField>
          <FormField
            label="Емейл владельца"
            error={errors.owner ? errors.owner.email : null}
          >
            <TextInput
              name="owner.email"
              type="email"
              value={values.owner.email}
              onChange={this.onFieldChange}
            />
          </FormField>
        </Box>
        <Box flex={false} as="footer" align="start">
          <Button label="Создать" onClick={submitForm} primary />
        </Box>
      </Box>
    )
  }
}

const mapDispatch = {
  addOrganization,
}

export default compose(
  connect(
    null,
    mapDispatch,
  ),
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    mapPropsToValues,
    handleSubmit,
  }),
)(NewEmployeeForm)
