const URL = window.URL || window.webkitURL

const TYPE_LIST = [
  { mime: 'image/gif', name: 'GIF' },
  { mime: 'image/png', name: 'PNG' },
]

const DEFAULT_SCREEN_SIZE = {
  width: 22,
  height: 22,
}

class ImageValidator {
  constructor({ sizes = [DEFAULT_SCREEN_SIZE], types = TYPE_LIST } = {}) {
    this.sizes = sizes
    this.allowedTypes = types
  }

  get allowedTypeNames() {
    return this.allowedTypes.map(type => type.name)
  }

  isValidType(type) {
    return this.allowedTypes.some(allowed => allowed.mime === type)
  }

  isValidSize(width, height) {
    return this.sizes.some(
      size => size.width === width && size.height === height,
    )
  }

  isValid(file) {
    return new Promise(resolve => {
      const result = {
        file,
        valid: false,
      }

      if (!this.isValidType(file.type)) {
        return resolve(result)
      }

      const image = new Image()
      image.onload = event => {
        const { width, height } = event.target
        if (!this.isValidSize(width, height)) {
        } else {
          result.valid = true
        }
        return resolve(result)
      }
      image.src = URL.createObjectURL(file)
    })
  }
}

export default ImageValidator
