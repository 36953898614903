import { handleActions } from 'redux-actions'
import { findByIdAndChange } from 'lib'
import * as types from './types'

const initialState = {
  list: [],
}

const handlers = {
  [types.FETCH_DEVICES_SUCCESS]: (state, action) => ({
    ...state,
    list: action.payload,
  }),
  [types.EDIT_DEVICE_SUCCESS]: (state, action) => ({
    ...state,
    list: findByIdAndChange(action, state.list, 'mac'),
  }),
}

export default handleActions(handlers, initialState)
