import React from 'react'
import {
  Router as ReactRouter,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { createBrowserHistory } from 'history'
import { Box, Heading } from 'grommet'
import { checkAccess, isLoading, isSignedIn } from 'modules/account'
import { Notification } from 'modules/notifications'
import { ErrorBoundary } from 'components'
import * as Page from '../pages'
import Home from './Home'

function RedirectToSignIn() {
  return <Redirect to="/sign-in" />
}

function Unauthorized() {
  return (
    <>
      <Route exact path="/sign-in" component={Page.SignIn} />
      <Route path="/" render={RedirectToSignIn} />
    </>
  )
}

const history = createBrowserHistory()

function LoadingView() {
  return (
    <Box fill justify="center" align="center">
      <Heading level={3}>Загрузка...</Heading>
    </Box>
  )
}

class Router extends React.PureComponent {
  componentDidMount() {
    const { checkAccess } = this.props
    checkAccess()
  }

  render() {
    const { loading, signedIn } = this.props
    return (
      <ReactRouter history={history}>
        <ErrorBoundary>
          {loading ? (
            <LoadingView />
          ) : (
            <>
              <Switch>{signedIn ? <Home /> : <Unauthorized />}</Switch>
              <Notification />
            </>
          )}
        </ErrorBoundary>
      </ReactRouter>
    )
  }
}

function mapState(state) {
  return {
    loading: isLoading(state),
    signedIn: isSignedIn(state),
  }
}

const mapDispatch = {
  checkAccess,
}

export default connect(
  mapState,
  mapDispatch,
)(Router)
