import React from 'react'
import {
  Box,
  Button,
  DropButton,
  Heading,
  Grid,
  Image,
  Layer,
  Text,
} from 'grommet'
import { Gallery, More, Tools, Trash, Upload } from 'grommet-icons'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import {
  addAssets,
  editAsset,
  fetchAssets,
  getAssets,
  removeAsset,
  removeMultipleAssets,
  buildContent,
  isUploadingAssets,
  isUploadingContent,
} from 'modules/organization'
import { ConfirmationDialog, Fab } from 'components'
import EditAssetForm from './EditAssetForm'
import './style.css'

const ACCEPTED_FILES = [
  { mime: 'image/png', extension: '.png' },
  { mime: 'image/gif', extension: '.gif' },
]

const ACCEPTED_FILE_MIMES = ACCEPTED_FILES.map(file => file.mime)

const ACCEPTED_FILE_EXTENSIONS_STRING = ACCEPTED_FILES.map(
  file => file.extension,
).join(', ')

function MenuItem({ children, onClick }) {
  return (
    <Button onClick={onClick} margin="small">
      {children}
    </Button>
  )
}

function SelectedAssetIcon({ index }) {
  return (
    <Box
      className="SelectedAsset"
      background="brand"
      align="center"
      justify="center"
      margin="small"
      pad={{ horizontal: 'xsmall' }}
      round
    >
      <Text size="small">{index + 1}</Text>
    </Box>
  )
}

class Assets extends React.PureComponent {
  state = {
    assetToRemove: null,
    editableAsset: null,
    openMenu: null,
    selectedAssets: [],
    confirmUploadDialogVisible: false,
    confirmBulkDeleteDialogVisible: false,
  }

  componentDidMount() {
    const { fetchAssets } = this.props
    fetchAssets()
  }

  onMenuButtonClick = assetId => () => {
    this.setState({ openMenu: assetId })
  }

  closeMenu = () => {
    this.setState({ openMenu: null })
  }

  onNewAssetDrop = files => {
    const { addAssets } = this.props
    addAssets(files)
  }

  onEditAssetClick = editableAsset => () => {
    this.setState({ editableAsset, openMenu: null })
  }

  updateAsset = changes => {
    const { editAsset } = this.props
    const { id } = this.state.editableAsset
    editAsset({ id, changes })
    this.setState({ editableAsset: null })
  }

  cancelAssetEditing = () => {
    this.setState({ editableAsset: null })
  }

  onAssetDeleteClick = assetToRemove => () => {
    this.setState({ assetToRemove, openMenu: null })
  }

  removeAsset = () => {
    const { removeAsset } = this.props
    const { assetToRemove, selectedAssets } = this.state
    if (selectedAssets.includes(assetToRemove.id)) {
      this.removeAssetFromSelected(assetToRemove.id)
    }
    removeAsset(assetToRemove.id)
    this.setState({ assetToRemove: null })
  }

  cancelRemoveAsset = () => {
    this.setState({ assetToRemove: null })
  }

  onAssetClick = id => () => {
    const { selectedAssets } = this.state
    if (selectedAssets.includes(id)) {
      this.removeAssetFromSelected(id)
    } else {
      this.addAssetToSelected(id)
    }
  }

  addAssetToSelected = id => {
    const { selectedAssets } = this.state
    const nextAssets = [...selectedAssets, id]
    this.setState({ selectedAssets: nextAssets })
  }

  removeAssetFromSelected = id => {
    const { selectedAssets } = this.state
    const nextAssets = selectedAssets.filter(assetId => assetId !== id)
    this.setState({ selectedAssets: nextAssets })
  }

  confirmUpload = () => {
    this.setState({ confirmUploadDialogVisible: true })
  }

  rejectUpload = () => {
    this.setState({ confirmUploadDialogVisible: false })
  }

  upload = () => {
    const { buildContent } = this.props
    const { selectedAssets } = this.state
    buildContent(selectedAssets)
    this.setState({
      selectedAssets: [],
      confirmUploadDialogVisible: false,
    })
  }

  confirmAssetsBulkDelete = () => {
    this.setState({ confirmBulkDeleteDialogVisible: true })
  }

  rejectAssetsBulkDelete = () => {
    this.setState({ confirmBulkDeleteDialogVisible: false })
  }

  deleteSelectedAssets = () => {
    const { removeMultipleAssets } = this.props
    const { selectedAssets } = this.state
    removeMultipleAssets(selectedAssets)
    this.setState({
      selectedAssets: [],
      confirmBulkDeleteDialogVisible: false,
    })
  }

  renderAsset = asset => {
    const { openMenu, selectedAssets } = this.state
    const { id, title, thumbnailUrl } = asset
    const selectedIndex = selectedAssets.indexOf(id)
    const selected = selectedIndex !== -1
    const Menu = (
      <Box>
        <MenuItem onClick={this.onEditAssetClick(asset)}>
          Переименовать
        </MenuItem>
        <MenuItem onClick={this.onAssetDeleteClick(asset)}>Удалить</MenuItem>
      </Box>
    )
    const border = selected
      ? {
          color: 'brand',
          size: 'medium',
        }
      : null
    return (
      <Box key={id} height="275px" width="auto">
        <Box direction="row" justify="between">
          <Text>{title}</Text>
          <DropButton
            open={openMenu === id}
            dropContent={Menu}
            onClick={this.onMenuButtonClick(id)}
            onClose={this.closeMenu}
          >
            <More />
          </DropButton>
        </Box>
        <Box
          className="AssetImageContainer"
          height="210px"
          margin={{ top: 'small' }}
          border={border}
        >
          <Image
            fit="cover"
            src={thumbnailUrl}
            onClick={this.onAssetClick(id)}
          />
          {selected && <SelectedAssetIcon index={selectedIndex} />}
        </Box>
      </Box>
    )
  }

  renderGrid = () => {
    const { assets } = this.props
    const {
      assetToRemove,
      editableAsset,
      confirmBulkDeleteDialogVisible,
    } = this.state
    return (
      <Box fill margin={{ top: 'small' }}>
        <Grid rows="flex" columns="small" gap="medium">
          {assets.map(this.renderAsset)}
        </Grid>
        {assetToRemove && (
          <ConfirmationDialog
            title="Удалить контент"
            message={`Вы уверены, что хотите удалить ${assetToRemove.title}?`}
            confirmLabel="Удалить"
            onConfirm={this.removeAsset}
            onCancel={this.cancelRemoveAsset}
          />
        )}
        {confirmBulkDeleteDialogVisible && (
          <ConfirmationDialog
            title="Удалить контент"
            message={`Вы уверены, что хотите удалить выбранные изображения?`}
            confirmLabel="Удалить"
            onConfirm={this.deleteSelectedAssets}
            onCancel={this.rejectAssetsBulkDelete}
          />
        )}
        {editableAsset && (
          <Layer
            onClickOutside={this.cancelAssetEditing}
            onEsc={this.cancelAssetEditing}
          >
            <EditAssetForm
              asset={editableAsset}
              onSubmit={this.updateAsset}
              onClose={this.cancelAssetEditing}
            />
          </Layer>
        )}
      </Box>
    )
  }

  renderEmpty = () => (
    <>
      <Gallery size="xlarge" color="brand" />
      <Box pad="medium">
        <Heading level={3} textAlign="center">
          Чтобы добавить изображения и анимации, перетащите их сюда
        </Heading>
      </Box>
      <Text margin="xsmall">{`Поддерживаются ${ACCEPTED_FILE_EXTENSIONS_STRING}`}</Text>
    </>
  )

  renderAssets = props => {
    const { assets } = this.props
    const { selectedAssets } = this.state
    return (
      <Box
        fill
        align="center"
        justify="center"
        pad="medium"
        {...props.getRootProps()}
      >
        {assets.length > 0 ? this.renderGrid() : this.renderEmpty()}
        {selectedAssets.length <= 0 && this.renderUploadButton(props)}
      </Box>
    )
  }

  renderUploadButton = props => {
    const actions = [
      {
        key: 'upload',
        icon: Upload,
        onClick: props.open,
      },
    ]
    return (
      <>
        <input {...props.getInputProps()} />
        <Fab actions={actions} />
      </>
    )
  }

  renderAssetsBulkButtons = () => {
    const actions = [
      {
        key: 'bulk-delete',
        icon: Trash,
        color: 'status-critical',
        onClick: this.confirmAssetsBulkDelete,
      },
      {
        key: 'build-assets',
        icon: Tools,
        onClick: this.confirmUpload,
      },
    ]
    return <Fab actions={actions} />
  }

  renderConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        title="Загрузить контент"
        message={[
          'Вы уверены, что хотите загрузить выбранный контент',
          'на все доступные экраны?',
          'Остановить загрузку будет невозможно.',
        ].join(' ')}
        confirmLabel="Загрузить"
        onConfirm={this.upload}
        onCancel={this.rejectUpload}
      />
    )
  }

  render() {
    const { assets, uploadingAssets, uploadingContent } = this.props
    const { selectedAssets, confirmUploadDialogVisible } = this.state
    const loading =
      !Array.isArray(assets) || uploadingContent || uploadingAssets
    const boxProps = {}
    if (loading) {
      boxProps.align = 'center'
      boxProps.justify = 'center'
    }
    return (
      <Box fill {...boxProps}>
        {loading ? (
          <Heading level={2}>Загрузка...</Heading>
        ) : (
          <>
            <Dropzone
              accept={ACCEPTED_FILE_MIMES}
              multiple
              onDrop={this.onNewAssetDrop}
              noClick
            >
              {this.renderAssets}
            </Dropzone>
            {selectedAssets.length > 0 && this.renderAssetsBulkButtons()}
            {confirmUploadDialogVisible && this.renderConfirmationDialog()}
          </>
        )}
      </Box>
    )
  }
}

function mapState(state) {
  return {
    assets: getAssets(state),
    uploadingAssets: isUploadingAssets(state),
    uploadingContent: isUploadingContent(state),
  }
}

const mapDispatch = {
  addAssets,
  editAsset,
  fetchAssets,
  removeAsset,
  removeMultipleAssets,
  buildContent,
}

export default connect(
  mapState,
  mapDispatch,
)(Assets)
