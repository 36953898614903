import { Endpoint, FileUploadRestEndpoint, RestEndpoint } from './classes'

class AuthEndpoint extends Endpoint {
  constructor(config) {
    super(config)
    this._storage = config.storage
  }

  async login(credentials) {
    const uri = `${this.path}/login`
    const { access_token, user } = await this._http.post(uri, credentials)
    await this._storage.setItem('token', access_token)
    return user
  }

  async hasCredentials() {
    const token = await this._storage.getItem('token')
    return Boolean(token)
  }

  logout() {
    return this._storage.removeItem('token')
  }
}

class AssetsEndpoint extends FileUploadRestEndpoint {
  _addThumbnail(asset) {
    return {
      ...asset,
      thumbnailUrl: this.getFileUrl(asset.id),
    }
  }

  async getAll() {
    const assets = await super.getAll()
    return assets.map(this._addThumbnail.bind(this))
  }

  async getOne(itemId, query) {
    const asset = await super.getOne(itemId, query)
    return this._addThumbnail(asset)
  }
}

class ContentPresetsEndpoint extends RestEndpoint {
  constructor(config) {
    super(config)
    this._createAssetThumbnailUrl = config.createAssetThumbnailUrl
  }

  _addThumbnail(asset) {
    return {
      ...asset,
      thumbnailUrl: this._createAssetThumbnailUrl(asset.id),
    }
  }

  _addThumbnailToAssets(preset) {
    return {
      ...preset,
      assets: preset.assets.map(this._addThumbnail.bind(this)),
    }
  }

  async getAll(query) {
    const presets = await super.getAll(query)
    return presets.map(this._addThumbnailToAssets.bind(this))
  }

  async getOne(itemId, query) {
    const preset = await super.getOne(itemId, query)
    return this._addThumbnailToAssets(preset)
  }

  build(itemId) {
    const uri = this._createUri({ itemId })
    return this._http.post(`${uri}/build`)
  }
}

class UsersEndpoint extends RestEndpoint {
  getCurrentProfile() {
    return this._http.get(`${this.path}/me`)
  }
}

export { AuthEndpoint, AssetsEndpoint, ContentPresetsEndpoint, UsersEndpoint }
