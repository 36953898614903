class Endpoint {
  constructor({ http, path }) {
    this._http = http
    this._path = path
  }

  get path() {
    return `/${this._path}`
  }
}

class RestEndpoint extends Endpoint {
  _createUri({ itemId = '', query } = {}) {
    let uri = this.path
    if (itemId) {
      uri += `/${itemId}`
    }
    if (query) {
      uri += `?${query}`
    }
    return uri
  }

  getAll(query) {
    const uri = this._createUri({ query })
    return this._http.get(uri)
  }

  getOne(itemId, query) {
    const uri = this._createUri({ itemId, query })
    return this._http.get(uri)
  }

  add(item) {
    const uri = this._createUri()
    return this._http.post(uri, item)
  }

  replace({ itemId, changes }) {
    const uri = this._createUri({ itemId })
    return this._http.put(uri, changes)
  }

  update({ itemId, changes }) {
    const uri = this._createUri({ itemId })
    return this._http.patch(uri, changes)
  }

  delete(itemId) {
    const uri = this._createUri({ itemId })
    return this._http.delete(uri)
  }
}

class FileUploadRestEndpoint extends RestEndpoint {
  getFileUrl(itemId) {
    const uri = this._createUri({ itemId })
    return this._http.createUri(`${uri}/file`)
  }

  add(file) {
    const uri = this._createUri()
    const formData = new FormData()
    formData.append('file', file)
    return this._http.post(uri, formData)
  }

  addMany(files) {
    const uploads = files.map(this.add.bind(this))
    return Promise.all(uploads)
  }
}

export { Endpoint, RestEndpoint, FileUploadRestEndpoint }
