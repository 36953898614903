class Storage {
  setItem(key, value) {
    window.localStorage.setItem(key, value && JSON.stringify(value))
  }

  getItem(key) {
    const value = window.localStorage.getItem(key)
    if (value === null || value === undefined) {
      return null // No item
    }
    return JSON.parse(value)
  }

  removeItem(key) {
    window.localStorage.removeItem(key)
  }

  clear() {
    window.localStorage.clear()
  }
}

export default new Storage()
