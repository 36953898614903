import { call, put } from 'redux-saga/effects'
import { notifyError } from 'modules/notifications'

function* handleErrorResponse(error) {
  console.error(error.data)
  if (error.message) {
    yield put(notifyError(error.message))
  }
}

function withErrorHandler(saga) {
  return function* handledSaga(...args) {
    try {
      return yield call(saga, ...args)
    } catch (err) {
      yield call(handleErrorResponse, err)
    }
  }
}

export default withErrorHandler
