export function findByIdAndChange(action, state, idFieldName = 'id') {
  const id = action.payload[idFieldName]
  const changes = action.payload.changes
  return state.map(item => {
    if (item[idFieldName] !== id) {
      return item
    }
    return {
      ...item,
      ...changes,
    }
  })
}

export function removeItemById(action, state, idFieldName = 'id') {
  return state.filter(item => item[idFieldName] !== action.payload)
}

export function bulkRemoveItemByIds(action, state, idFieldName = 'id') {
  return state.filter(item => !action.payload.includes(item[idFieldName]))
}
