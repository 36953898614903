import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Box, Button, FormField, Heading, Select, TextInput } from 'grommet'
import { Email } from 'validation'
import { Close } from 'grommet-icons'
import { addEmployee, getCities } from 'modules/organization'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

const validationSchema = Yup.object().shape({
  email: Email,
  fullName: Yup.string().required('Имя не может быть пустым'),
  city: Yup.string().required('Город не может быть пустым'),
})

function handleSubmit(values, { props }) {
  props.addEmployee(values)
  props.onClose()
}

class NewEmployeeForm extends React.Component {
  onFieldChange = event => {
    const { setFieldValue } = this.props
    setFieldValue(event.target.name, event.target.value)
  }

  onCitySelected = ({ option }) => {
    const { setFieldValue } = this.props
    setFieldValue('city', option)
  }

  render() {
    const { values, errors, cities, submitForm, onClose } = this.props
    return (
      <Box
        as="form"
        fill="vertical"
        overflow="auto"
        width="medium"
        pad="medium"
      >
        <Box flex={false} direction="row" justify="between">
          <Heading level={2} margin="none">
            Новый сотрудник
          </Heading>
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        <Box flex="grow" overflow="auto" pad={{ vertical: 'medium' }}>
          <FormField label="Полное имя" error={errors.fullName}>
            <TextInput
              autoFocus
              name="fullName"
              value={values.fullName}
              onChange={this.onFieldChange}
            />
          </FormField>
          <FormField label="Емейл" error={errors.email}>
            <TextInput
              name="email"
              type="email"
              value={values.email}
              onChange={this.onFieldChange}
            />
          </FormField>
          <FormField label="Город" error={errors.city}>
            <Select
              value={values.city}
              options={cities}
              onChange={this.onCitySelected}
            />
          </FormField>
        </Box>
        <Box flex={false} as="footer" align="start">
          <Button label="Создать" onClick={submitForm} primary />
        </Box>
      </Box>
    )
  }
}

NewEmployeeForm.propTypes = propTypes

function mapState(state) {
  return {
    cities: getCities(state),
  }
}

const mapDispatch = {
  addEmployee,
}

export default compose(
  connect(
    mapState,
    mapDispatch,
  ),
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    handleSubmit,
  }),
)(NewEmployeeForm)
