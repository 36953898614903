import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Box, Button, FormField, Heading, Text, TextInput } from 'grommet'
import { Close } from 'grommet-icons'

const ACCEPTABLE_FILE_TYPES = ['.bin', '.pdf']

const validationSchema = Yup.object().shape({
  version: Yup.string().required('Версия не может быть пустой'),
})

function handleSubmit(values, { props }) {
  props.onClose()
}

class NewFirmwareForm extends React.Component {
  onFieldChange = event => {
    const { setFieldValue } = this.props
    setFieldValue({ [event.target.name]: event.target.value })
  }

  onFirmwareDrop = file => {
    const { setFieldValue } = this.props
    setFieldValue('file', file)
  }

  renderDropzone = props => {
    return (
      <Box
        height="small"
        overflow="hidden"
        margin="small"
        background="light-2"
        justify="center"
        align="center"
        border={{ style: 'dashed', color: 'dark-2' }}
      >
        <Text>Перетащите файл прошивки сюда</Text>
      </Box>
    )
  }

  render() {
    const { values, errors, submitForm, onClose } = this.props
    const disabled = !values.version && !values.file
    return (
      <Box as="form" overflow="auto" width="large" pad="large">
        <Box flex={false} direction="row" justify="between">
          <Heading level={2} margin="none">
            Новая прошивка
          </Heading>
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        <Box flex="grow" pad={{ vertical: 'small' }}>
          <FormField label="Версия" error={errors.version}>
            <TextInput
              autoFocus
              name="version"
              value={values.version}
              onChange={this.onFieldChange}
            />
          </FormField>
          <Dropzone accept={ACCEPTABLE_FILE_TYPES} onDrop={this.onFirmwareDrop}>
            {this.renderDropzone}
          </Dropzone>
        </Box>
        <Box flex={false} as="footer" align="start">
          <Button
            label="Загрузить"
            onClick={submitForm}
            primary
            disabled={disabled}
          />
        </Box>
      </Box>
    )
  }
}

const mapDispatch = {}

export default compose(
  connect(
    null,
    mapDispatch,
  ),
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    handleSubmit,
  }),
)(NewFirmwareForm)
