import { BUILD_STATUS } from './common'

function module(state) {
  return state.organization.content
}

export function getContentPresets(state) {
  const { list } = module(state)
  const currentPreset = list
    .reverse()
    .find(preset => preset.status === 'enabled')
  const otherPresets = currentPreset
    ? list.filter(preset => currentPreset.id !== preset.id)
    : list
  return {
    list: otherPresets,
    current: currentPreset,
  }
}

export function isLoadingContentPresets(state) {
  return module(state).fetching
}

export function getCurrentContentPreset(state) {
  const list = getContentPresets(state)
  return list.reverse().find(preset => preset.status === 'enabled')
}

export function isUploadingContent(state) {
  return module(state).status === BUILD_STATUS.PROGRESS
}
