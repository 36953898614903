import React from 'react'
import { connect } from 'react-redux'
import { hide } from './actions'
import { getNotification } from './selectors'
import Alert from './Alert'
import Toast from './Toast'

const StatusComponent = {
  alert: Alert,
  error: Toast,
  success: Toast,
}

class Notification extends React.Component {
  render() {
    const { type, message, hide, ...rest } = this.props
    if (!type || !message) {
      return null
    }
    const Component = StatusComponent[type]
    return <Component type={type} message={message} onClose={hide} {...rest} />
  }
}

function mapState(state) {
  return getNotification(state)
}

const mapDispatch = {
  hide,
}

export default connect(
  mapState,
  mapDispatch,
)(Notification)
