import { put, select, takeLeading } from 'redux-saga/effects'
import { notifySuccess } from 'modules/notifications'
import * as actions from './actions'
import { getEmployees } from './selectors'
import * as types from './types'

function* handleFetchEmployees() {
  yield put(actions.fetchedEmployees([]))
}

function* handleAddEmployee({ payload: employee }) {
  const employees = yield select(getEmployees)
  const last = employees[employees.length - 1]
  const lastId = last ? last.id : 0
  yield put(
    actions.addedEmployee({
      id: lastId + 1,
      ...employee,
    }),
  )
  yield put(notifySuccess(`Отправлено приглашение на ${employee.email}`))
}

function* handleEditEmployee({ payload: { id, changes } }) {
  yield put(actions.editedEmployee({ id, changes }))
  yield put(notifySuccess('Ваши изменения сохранены'))
}

function* handleRemoveEmployee({ payload: employeeId }) {
  yield put(actions.removedEmployee(employeeId))
  yield put(notifySuccess('Сотрудник удалён'))
}

function* main() {
  yield takeLeading(types.FETCH_EMPLOYEES, handleFetchEmployees)
  yield takeLeading(types.ADD_EMPLOYEE, handleAddEmployee)
  yield takeLeading(types.EDIT_EMPLOYEE, handleEditEmployee)
  yield takeLeading(types.REMOVE_EMPLOYEE, handleRemoveEmployee)
}

export default main
