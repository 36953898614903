export function getUser(state) {
  return state.account.user
}

export function isSignedIn(state) {
  return Boolean(getUser(state))
}

export function isLoading(state) {
  return state.account.loading
}

export function isSigningIn(state) {
  return state.account.signingIn
}
