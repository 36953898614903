import React from 'react'
import { connect } from 'react-redux'
import { Box, DataTable, Text } from 'grommet'
import { fetchDevices, getDevices, editDevice } from 'modules/organization'
import { CitySelector } from 'components'
import DeviceStatusLabel from './DeviceStatusLabel'

class Devices extends React.Component {
  componentDidMount() {
    const { fetchDevices } = this.props
    fetchDevices()
  }

  onCityChanged = (device, newCity) => {
    const { editDevice } = this.props
    editDevice({
      id: device.mac,
      changes: {
        city: newCity,
      },
    })
  }

  tableConfig = [
    {
      property: 'mac',
      header: <Text>Устройство</Text>,
      primary: true,
      search: true,
    },
    {
      property: 'city',
      header: <Text>Город</Text>,
      render: device => (
        <CitySelector
          city={device.city}
          onSelect={city => this.onCityChanged(device, city)}
        />
      ),
      align: 'center',
    },
    {
      property: 'status',
      header: <Text>Статус</Text>,
      render: device => <DeviceStatusLabel status={device.status} />,
      align: 'center',
    },
  ]

  render() {
    const { devices } = this.props
    return (
      <Box pad="medium">
        <DataTable
          columns={this.tableConfig}
          data={devices}
          size="xlarge"
          sortable
        />
      </Box>
    )
  }
}

function mapState(state) {
  return {
    devices: getDevices(state),
  }
}

const mapDispatch = {
  editDevice,
  fetchDevices,
}

export default connect(
  mapState,
  mapDispatch,
)(Devices)
