import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Box, Button, Form, FormField, Heading } from 'grommet'
import { Email, Password } from 'validation'
import { signIn, isSigningIn } from 'modules/account'

const validationSchema = Yup.object().shape({
  email: Email,
  password: Password,
})

function handleSubmit(values, { props }) {
  props.signIn({
    username: values.email,
    password: values.password,
  })
}

class SignIn extends React.PureComponent {
  onFieldChange = event => {
    const { setFieldValue } = this.props
    setFieldValue(event.target.name, event.target.value)
  }

  onKeyPress = event => {
    if (event.key === 'Enter') {
      this.props.submitForm()
    }
  }

  render() {
    const { values, errors, loading, submitForm } = this.props
    return (
      <Box fill align="center" justify="center" animation="fadeIn" gap="medium">
        <Heading>Войти</Heading>
        <Form>
          <FormField
            autoFocus
            name="email"
            label="Емейл"
            type="email"
            value={values.email}
            error={errors.email}
            disabled={loading}
            onChange={this.onFieldChange}
            onKeyPress={this.onKeyPress}
          />
          <FormField
            name="password"
            label="Пароль"
            type="password"
            value={values.password}
            error={errors.password}
            disabled={loading}
            onChange={this.onFieldChange}
            onKeyPress={this.onKeyPress}
          />
          <Button
            primary
            label="Войти"
            disabled={loading}
            onClick={submitForm}
          />
        </Form>
      </Box>
    )
  }
}

function mapState(state) {
  return {
    loading: isSigningIn(state),
  }
}

const mapDispatch = {
  signIn,
}

export default compose(
  connect(
    mapState,
    mapDispatch,
  ),
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    handleSubmit,
  }),
)(SignIn)
