import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { Box, Heading } from 'grommet'
import { getUser } from 'modules/account'
import HomeLayout from './HomeLayout'
import getHomeRoutes from './homeRoutes'

function NotFound() {
  return (
    <Box fill align="center" justify="center">
      <Heading level={3}>Страница не найдена</Heading>
    </Box>
  )
}

class Home extends React.PureComponent {
  componentDidMount() {
    const { history, location } = this.props
    if (location.pathname === '/sign-in') {
      history.replace('/')
    }
  }

  renderRoute = props => <Route key={props.path} {...props} />

  render() {
    const { user } = this.props
    const routes = getHomeRoutes(user.role)
    return (
      <HomeLayout navBarRoutes={routes}>
        <Switch>
          {routes.map(this.renderRoute)}
          <Route render={NotFound} />
        </Switch>
      </HomeLayout>
    )
  }
}

function mapState(state) {
  return {
    user: getUser(state),
  }
}

export default connect(mapState)(withRouter(Home))
