import React from 'react'
import { connect } from 'react-redux'
import { Box, Button, DataTable, Layer, Text } from 'grommet'
import { Trash } from 'grommet-icons'
import {
  fetchEmployees,
  getEmployees,
  editEmployee,
  removeEmployee,
} from 'modules/organization'
import { CitySelector, ConfirmationDialog } from 'components'
import NewEmployeeForm from './NewEmployeeForm'

class Employees extends React.Component {
  state = {
    employeeToRemove: null,
    newEmployeeFormVisible: false,
  }

  componentDidMount() {
    const { fetchEmployees } = this.props
    fetchEmployees()
  }

  confirmRemoveEmployee = employeeToRemove => {
    this.setState({ employeeToRemove })
  }

  removeEmployee = () => {
    const { removeEmployee } = this.props
    const { employeeToRemove } = this.state
    removeEmployee(employeeToRemove.id)
    this.setState({ employeeToRemove: null })
  }

  cancelRemoveEmployee = () => {
    this.setState({ employeeToRemove: null })
  }

  showNewEmployeeForm = () => {
    this.setState({ newEmployeeFormVisible: true })
  }

  hideNewEmployeeForm = () => {
    this.setState({ newEmployeeFormVisible: false })
  }

  onCityChanged = (employee, newCity) => {
    const { editEmployee } = this.props
    editEmployee({
      id: employee.id,
      changes: {
        city: newCity,
      },
    })
  }

  tableConfig = [
    {
      property: 'fullName',
      header: <Text>Полное имя</Text>,
      primary: true,
      search: true,
    },
    {
      property: 'email',
      header: <Text>Емейл</Text>,
      search: true,
    },
    {
      property: 'city',
      header: <Text>Город</Text>,
      render: employee => (
        <CitySelector
          city={employee.city}
          onSelect={city => this.onCityChanged(employee, city)}
        />
      ),
      align: 'center',
    },
    {
      property: '—',
      header: (
        <Button label="Новый сотрудник" onClick={this.showNewEmployeeForm} />
      ),
      render: employee => (
        <Button
          icon={<Trash />}
          onClick={() => this.confirmRemoveEmployee(employee)}
        />
      ),
      align: 'center',
      sortable: false,
    },
  ]

  render() {
    const { employees } = this.props
    const { employeeToRemove, newEmployeeFormVisible } = this.state
    return (
      <Box pad="medium">
        <DataTable
          columns={this.tableConfig}
          data={employees}
          sortable
          size="xlarge"
        />
        {employeeToRemove && (
          <ConfirmationDialog
            title="Удалить сотрудника"
            message={`Вы уверены, что хотите удалить ${employeeToRemove.fullName}?`}
            confirmLabel="Удалить"
            onConfirm={this.removeEmployee}
            onCancel={this.cancelRemoveEmployee}
          />
        )}
        {newEmployeeFormVisible && (
          <Layer
            position="right"
            full="vertical"
            modal
            onClickOutside={this.hideNewEmployeeForm}
            onEsc={this.hideNewEmployeeForm}
          >
            <NewEmployeeForm onClose={this.hideNewEmployeeForm} />
          </Layer>
        )}
      </Box>
    )
  }
}

function mapState(state) {
  return {
    employees: getEmployees(state),
  }
}

const mapDispatch = {
  fetchEmployees,
  editEmployee,
  removeEmployee,
}

export default connect(
  mapState,
  mapDispatch,
)(Employees)
