import { createAction } from 'redux-actions'
import * as types from './types'

export const fetchEmployees = createAction(types.FETCH_EMPLOYEES)
export const fetchedEmployees = createAction(types.FETCH_EMPLOYEES_SUCCESS)

export const addEmployee = createAction(types.ADD_EMPLOYEE)
export const addedEmployee = createAction(types.ADD_EMPLOYEE_SUCCESS)

export const editEmployee = createAction(types.EDIT_EMPLOYEE)
export const editedEmployee = createAction(types.EDIT_EMPLOYEE_SUCCESS)

export const removeEmployee = createAction(types.REMOVE_EMPLOYEE)
export const removedEmployee = createAction(types.REMOVE_EMPLOYEE_SUCCESS)
