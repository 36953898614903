import { createAction } from 'redux-actions'
import { BUILD_STATUS } from './common'
import * as types from './types'

export const fetchContent = createAction(types.FETCH_CONTENT)
export const fetchedContent = createAction(types.FETCH_CONTENT_SUCCESS)

const setContentUploadStatus = createAction(types.CONTENT_BUILD_STATUS_CHANGE)

export const buildContent = createAction(types.BUILD_CONTENT)
export const resetBuildStatus = () => setContentUploadStatus(BUILD_STATUS.IDLE)
export const contentBuildComplete = () =>
  setContentUploadStatus(BUILD_STATUS.SUCCESS)
export const contentBuildFailed = () =>
  setContentUploadStatus(BUILD_STATUS.ERROR)
